import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import Home from "pages/Home.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  // return <AnimationRevealPage disab/* Ready Made Pages (from demos folder) */led></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/sobre">
          <AboutUsPage />
        </Route>
        <Route path="/contato">
          <ContactUsPage />
        </Route>
      </Switch>
    </Router>
  );
}


