import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

import Img1 from "images/img1.jpg";
import Img2 from "images/img2.jpg";
import Img3 from "images/img3.jpg";
import Img4 from "images/img4.jpg";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Quem Somos</Subheading>}
        heading="Sobre nossa empresa"
        imageSrc={Img1}
        description="A Acontece Construtora é uma empresa do ramo da construção civil que nasceu com a missão de atender com excelência no atendimentos aos clientes, priorizando a qualidade e o bom relacionamento. Nossas premissas são o respeito com os prazos, cordialidade e sempre surpreender você."
      />
      <MainFeature1
        subheading={<Subheading>Nossa Missão</Subheading>}
        heading="Prezamos a responsabilidade"
        imageSrc={Img2}
        textOnLeft={false}
        description="Atuar com responsabilidade no ramo da construção civil, tendo como premissa a satisfação do cliente em todos os sentidos."
      />
      <MainFeature1
        subheading={<Subheading>Nossos Valores</Subheading>}
        heading="Compromisso e respeito"
        imageSrc={Img3}
        description="Temos um grande compromisso e respeito com o patrimônio de nossos clientes, primamos pela satisfação cumprindo os prazos de entrega de nossas construções."
      />
      <MainFeature1
        subheading={<Subheading>Nosso propósito</Subheading>}
        heading="O que nos guia"
        imageSrc={Img4}
        textOnLeft={false}
        description="Servir com excelência todos os nossos clientes em todos os padrões e seguimentos da construção civil."
      />

      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
