import React, { useState, useCallback } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import swal from 'sweetalert';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ContactImg from "images/contact.svg";
import api from 'services/api';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 mr-4 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({
  subheading = "contato",
  heading = <>Entre em <span tw="text-primary-500">contato</span><wbr/> conosco</>,
  description = "Se preferir, pode enviar um e-mail direto para nós: contato@acontececonstrutora.com.br",
  submitButtonText = "Enviar",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [fields, setFields] = useState({
    name: '',
    whatsapp: '',
  });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { name, whatsapp } = fields;
    const email = 'contato@acontececonstrutora.com.br';
    const subject = `${name} solicitou o contato através do site`;
    const message = `${name} solicitou o contato através do site e informou seu número de whatsapp: ${whatsapp}`;

    const response = await api.post('/send', {name, email, subject, message});

    if(response.status === 200) {
      swal("Sucesso!", "E-mail enviado com êxito!", "success");
    } else {
      swal("Oops!", "Aconteceu algo de errado, tente novamente!", "error");
    }


  }, [fields]);

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={ContactImg} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Form onSubmit={handleSubmit}>
              <Input type="text" name="name" placeholder="Seu nome" onChange={(e) => setFields({...fields, name: e.target.value})}/>
              <Input type="text" name="whatsapp" placeholder="Seu whatsapp" onChange={(e) => setFields({...fields, whatsapp: e.target.value})}/>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
