import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
// import ResponsiveImage from "../../helpers/ResponsiveImage.js";
import BgImg from "../../images/bg-company.jpg";
// import AconteceHeaderImg from "images/acontece-header.jpg";
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

import homeVideo from '../../videos/home.mp4';

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${BgImg});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0  opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-yellow-500 font-medium text-base`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

// const StyledResponsiveImage = styled(ResponsiveImage)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block content-center text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const FacebookIcon = styled(FaFacebook)`
  @media only screen and (max-width: 1025px) {
    color: rgba(0,0,0);
  }
`;
const InstagramIcon = styled(FaInstagram)`
  ${tw`mt-4`}
  @media only screen and (max-width: 1025px) {
    color: rgba(0,0,0);
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">
        Home
      </NavLink>
      <NavLink href="/sobre">
        Nossa empresa
      </NavLink>
      <NavLink href="/contato">
        Contato
      </NavLink>
      <SocialLink href="https://facebook.com/acontece.construtora1" target="_blank" rel="noopener noreferrer">
        <FacebookIcon/>
      </SocialLink>
      <SocialLink href="https://instagram.com/acontece.construtora/" target="_blank" rel="noopener noreferrer">
        <InstagramIcon />
      </SocialLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer id="hero-container">
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <br/>
            <Notification>Onde seu sonho Acontece</Notification>
            <Heading>
              <span>Conquiste sua</span>
              <br />
              <SlantedBackground>casa própria</SlantedBackground>
            </Heading>
            <a href="https://api.whatsapp.com/send?phone=5514997418116" target="_blank" rel="noopener noreferrer"><PrimaryAction>Simule um financiamento</PrimaryAction></a>
          </LeftColumn>
          <RightColumn>
          <StyledResponsiveVideoEmbed
              url={homeVideo}
              background="transparent"
            />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
